import {apiClient as client} from '@/shared/service/api-client';

export default {
    async get() {
        return await client.get('/corp/orderRatio/get');
    },
    async save(params) {
        return await client.post('/corp/orderRatio/save', params);
    }
}
