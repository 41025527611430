<template>
  <div class="order-setup relative">
    <div class="discount-form">
      <a-form ref="discountFormRef"
              :model="discountForm"
              :rules="discountRules"
              :colon="false">
        <a-form-item label="订单折扣权限"
                     name="enabled">
          <a-radio-group v-model:value="discountForm.enabled">
            <a-radio :value="1">开启</a-radio>
            <a-radio :value="0">关闭</a-radio>
          </a-radio-group>
        </a-form-item>
        <div v-show='discountForm.enabled'>
          <a-form-item label="订单价格上限为原价的"
                       name="upperLimit">
            <a-input v-model:value="discountForm.upperLimit"
                     type="number"
                     suffix="%"
                     step='10' />
          </a-form-item>
          <a-form-item label="订单价格下限为原价的"
                       name="lowerLimit">
            <a-input v-model:value="discountForm.lowerLimit"
                     type="number"
                     suffix="%"
                     step='10' />
          </a-form-item>
        </div>
      </a-form>
    </div>

    <div class="protocol-form">
      <a-form ref="protocolFormRef"
              :model="protocolForm"
              :rules="protocolRules"
              :colon="false"
              hideRequiredMark>
        <a-form-item ref="enabled"
                     name="enabled">
          <template #label>
            购买协议
            <a-tooltip>
              <template #title>开启用户协议后，商品订单页面会显示用户协议内容，客户同意后才可以支付。</template>
              <Icon class="icon-yiwen"
                    type="iconyiwen"></Icon>
            </a-tooltip>

          </template>
          <a-radio-group v-model:value="protocolForm.enabled">
            <a-radio :value="1">开启</a-radio>
            <a-radio :value="0">关闭</a-radio>
          </a-radio-group>
        </a-form-item>
        <div v-show="protocolForm.enabled === 1">
          <a-form-item label="协议名称"
                       name="name"
                       :wrapperCol="{span: 10}">
            <a-input v-model:value="protocolForm.name"
                     placeholder="请输入协议名称，展示在客户订单页面，如XXX用户购买协议" />
          </a-form-item>
          <a-form-item label="协议内容"
                       name="protocol"
                       :wrapperCol="{span: 10}">
            <div class="editor__container">
              <ckeditor :editor="editor"
                        @ready="onReady"
                        v-model="protocolForm.protocol"
                        :config="editorConfig" />
              <div id="word-count"></div>
            </div>
          </a-form-item>
        </div>

      </a-form>

    </div>

    <protocol-display v-if="protocolForm.enabled"
                      :protocolInfo="protocolForm"></protocol-display>
  </div>

  <div class="save-btn mx-auto">
    <a-button type="primary"
              @click="onSubmit"
              :loading="submitLoading">
      保存
    </a-button>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import _ from "lodash";
import { Radio, message, Tooltip } from "ant-design-vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn";

import Icon from "@/components/SvgIcon";
import ProtocolDisplay from "./DisplayProtocol.vue";

import orderRatioApi from "@/service/api/orderRatio";
import orderProtocolApi from "@/service/api/orderProtocol";

export default defineComponent({
  name: "OrderSetup",
  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ATooltip: Tooltip,
    Icon,
    ckeditor: CKEditor.component,
    ProtocolDisplay,
  },
  setup() {
    async function getOrderRatio() {
      const res = await orderRatioApi.get();
      _.assign(discountForm, res);
    }
    async function getOrderProtocol() {
      const res = await orderProtocolApi.get();
      _.assign(protocolForm, res);
    }

    const discountRules = reactive({
      upperLimit: [
        {
          validator: checkUpperLimit,
          trigger: "blur",
        },
      ],
      lowerLimit: [
        {
          validator: checkLowerLimit,
          trigger: "blur",
        },
      ],
    });
    const discountForm = reactive({
      enabled: 0,
      upperLimit: 200,
      lowerLimit: 80,
    });

    const discountFormRef = ref();
    function checkRatioNum(value) {
      if (_.includes(value, ".")) {
        return "不能为小数";
      }
      if (1 / value === -Infinity) {
        return "不能为负数";
      }

      return "";
    }
    function checkUpperLimit(rule, value) {
      const value_num = Number(value);
      if (!value) {
        return Promise.reject("上限不能为空");
      }

      if (value_num !== 0) {
        value = _.trimStart(value, "0");
      }

      if (value_num < 0) {
        return Promise.reject("上限不能小于0");
      }
      if (value_num > 10000) {
        return Promise.reject("上限不能大于10000");
      }
      if (checkRatioNum(value)) {
        return Promise.reject(checkRatioNum(value));
      }
      if (Number(discountForm.lowerLimit) > Number(discountForm.upperLimit)) {
        return Promise.reject("上限不能低于下限");
      }

      return Promise.resolve();
    }
    function checkLowerLimit(rule, value) {
      const value_num = Number(value);

      if (value_num !== 0) {
        value = _.trimStart(value, "0");
      }
      if (value === "") {
        return Promise.reject("下限不能为空");
      }
      if (value_num < 0) {
        return Promise.reject("下限不能小于0");
      }
      if (value_num > 10000) {
        return Promise.reject("下限不能大于10000");
      }
      if (checkRatioNum(value)) {
        return Promise.reject(checkRatioNum(value));
      }
      if (Number(discountForm.lowerLimit) > Number(discountForm.upperLimit)) {
        return Promise.reject("下限不能高于上限");
      }
      return Promise.resolve();
    }

    const protocolRules = reactive({
      name: [
        {
          required: true,
          message: "协议标题不能为空",
          trigger: "blur",
          whitespace: true,
        },
      ],
      protocol: [
        {
          required: true,
          message: "协议内容不能为空",
          trigger: "blur",
          whitespace: true,
        },
      ],
    });
    const protocolForm = reactive({
      enabled: 0,
      name: undefined,
      protocol: undefined,
    });
    const protocolFormRef = ref();

    const submitLoading = ref(false);

    async function onSubmit() {
      let validate = true;
      submitLoading.value = true;
      if (discountForm.enabled) {
        await discountFormRef.value
          .validate()
          .then(() => {
            discountForm;
          })
          .catch(() => {
            validate = false;
          });
      }

      if (protocolForm.enabled) {
        await protocolFormRef.value.validate().catch(() => {
          validate = false;
        });
      }

      if (validate) {
        await orderRatioApi.save(discountForm);
        await orderProtocolApi.save(protocolForm);
        message.success("保存成功");
      }
      submitLoading.value = false;
    }

    onMounted(() => {
      getOrderRatio();
      getOrderProtocol();
    });

    return {
      discountRules,
      discountForm,
      discountFormRef,
      checkRatioNum,

      protocolRules,
      protocolForm,
      protocolFormRef,

      submitLoading,
      onSubmit,
    };
  },
  data() {
    return {
      editor: DecoupledEditor,
      editorConfig: {
        language: "zh-cn", // 中文
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "|",
            "fontColor",
            "fontSize",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "redo",
          ],
          shouldNotGroupWhenFull: true,
        },

        fontSize: {
          options: [12, 14, 16, 18, 20],
        },
      },
    };
  },
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
  },
});
</script>
<style lang="less" scoped>
.order-setup {
  .ant-form-item {
    color: @gray-darker;

    &-label {
      margin-right: @spacing-4x;

      width: 140px;
      text-align: right;

      & > label::after {
        margin: 0;
      }
    }

    .ant-input {
      &-suffix {
        color: @gray;
      }
    }

    .ant-radio-wrapper {
      margin-right: 44px;
    }
  }

  .protocol-form {
    position: relative;

    .icon-yiwen {
      margin-top: 12px;
      font-size: 14px;
      color: @icon;
    }

    .editor__container {
      .ck.ck-toolbar,
      .ck.ck-editor__editable_inline {
        border: 1px solid @border;
      }

      .ck.ck-editor__editable:not(.ck-editor__neste).ck-focused {
        box-shadow: none;
      }
    }

    .ck-editor__editable {
      height: 300px;
      overflow-y: auto;
    }
  }
}
</style>
